import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './utils/auth.guard';
import { LoginSelectionComponent } from './accounts/login-selection/login-selection.component';

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule) },
  {path: '', redirectTo: '/dashboard', pathMatch: 'full' , canActivate: [AuthGuard]},
  {path:'',loadChildren: () => import('./oo-pages/oo-pages.module').then(m => m.OoPagesModule)},
  // {path: 'login-selection', component: LoginSelectionComponent }
  ];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
