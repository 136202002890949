<!-- <div>
  <h1>ControlFMS Contractor Portal</h1>

  <button (click)="requestTest()">Test</button>

  <pre>{{testData | json}}</pre>
</div> -->

<!-- <router-outlet></router-outlet> -->

<!-- <app-header></app-header>
<div class="main-container">
  <div class="container-fluid pt-3 mb-3 pb-3">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer> -->
<app-loader></app-loader>

<router-outlet></router-outlet>

