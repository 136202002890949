import { Component, Inject, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'control-contractor-portal-ui';

  testData: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public httpClient: HttpClient,
  ) {}

  async requestTest() {
    // const localhost = 'http://localhost:3200'
    // const origin = this.document.location.origin
    // const baseUrl = isDevMode() ? localhost : origin

    // const data = await firstValueFrom(this.httpClient.get(`${baseUrl}/api/test`))
    // this.testData = data;
  }
}
